<template>
	<div class="bg-overlay-texture">
		<div class="bg-overlay-texture__inner-wrapper" ref="inner-wrapper">
			<img src="../assets/images/bg-floral_01_b.jpg" alt="" class="bg-overlay-texture__image">
			<img src="../assets/images/bg-floral_01_b.jpg" alt="" class="bg-overlay-texture__image">
		</div>
	</div>
</template>

<script>
import { gsap } from "gsap"

export default {
	name: "BgOverlayTexture",

	data() {
		return {
			loopAnimation: null,
			loopOptions: {
				segmentDuration: 8,
				crossfadeDuration: 2
			}
		}
	},

	methods: {
		createLoopEffect() {
			const images = this.$refs[ "inner-wrapper" ].querySelectorAll( ".bg-overlay-texture__image" )

			this.loopAnimation = gsap.timeline()
				.set( images, {
					transformOrigin: () => window.innerWidth <= window.innerHeight ? "50% 50%" : "30% 50%"
				} )

				.to( images, {
					scale: 0.75,
					duration: this.loopOptions.segmentDuration,
					ease: "linear",
					stagger: {
						each: this.loopOptions.segmentDuration / 2,
						repeat: -1
					}
				} )

				.from( images, {
					opacity: 0,
					duration: this.loopOptions.crossfadeDuration,
					ease: "power1",
					stagger: {
						each: this.loopOptions.segmentDuration / 2,
						repeat: -1,
						repeatDelay: this.loopOptions.segmentDuration - this.loopOptions.crossfadeDuration
					}
				}, 0 )

				.to( images, {
					opacity: 0,
					duration: this.loopOptions.crossfadeDuration,
					ease: "power1.in",
					stagger: {
						each: this.loopOptions.segmentDuration / 2,
						repeat: -1,
						repeatDelay: this.loopOptions.segmentDuration - this.loopOptions.crossfadeDuration
					}
				}, this.loopOptions.segmentDuration - this.loopOptions.crossfadeDuration )
		}
	},

	mounted() {
		if( window.innerWidth > 479 ) this.createLoopEffect()
	}
	
}
</script>

<style lang="postcss">
.bg-overlay-texture {
	position: absolute;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	mix-blend-mode: multiply;
	overflow: hidden;

	&__inner-wrapper {
		position: relative;
		display: grid;
		justify-content: center;
		align-items: center;
		width: 100%;
		will-change: transform;
	}

	&__image {
		position: relative;
		grid-area: 1 / 1;
		/* width: 100%; */
		width: 125vw;
		height: 125vh;
		object-fit: cover;
		will-change: transform;
		justify-content: center;
		align-items: center;
	}
}
</style>