<template>
	<div class="side-nav" ref="side-nav">
		<div class="side-nav__tokens-container">
			<div class="side-nav__token"></div>
			<div class="side-nav__token"></div>
			<div class="side-nav__token"></div>
		</div>
	</div>
</template>

<script>
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin( ScrollTrigger )

export default {
	name: "SideNav",

	methods: {
		toggleCurrent() {
			const tokens = this.$refs[ "side-nav" ].querySelectorAll( ".side-nav__token" )

			tokens.forEach( ( target, index ) => {
				gsap.to( target, {
					scale: 1,
					opacity: 1,
					duration: 0.5,
					ease: "power2.inOut",
					scrollTrigger: {
						trigger: `.body-content__section:nth-of-type( ${ index + 1 } )`,
						start: "top 50%",
						end: "bottom 50%",
						toggleActions: "play reverse restart reverse"
					}
				} )
			} )
		}
	},

	mounted() {
		this.toggleCurrent()
	}
}
</script>

<style>
.side-nav {
	position: fixed;
	left: calc( var( --layout-column ) );
	top: 0;
	width: 0;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	&__tokens-container {
		position: relative;
		height: calc( var( --grid-tile ) * 4.5 );
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between;
		align-items: center;
	}

	&__token {
		position: relative;
		width: calc( var( --grid-tile ) / 2 );
		height: calc( var( --grid-tile ) / 2 );
		border-radius: 50%;
		border: var( --line-regular ) solid rgba( var( --color ), 1 );
		transform: scale( 0.5 );
		opacity: 0.25;

		&--current {
			transform: scale( 1 );
			opacity: 1;
		}
	}
}
</style>