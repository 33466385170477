<template>
	<footer class="site-footer">
		<div class="site-footer__container site-footer__container--scroll-arrow">
			<v-text-element
				label="↓"
				:is-strong="true"
				section="1"
			></v-text-element>

			<v-text-element
				label="↓"
				:is-strong="true"
				section="2"
			></v-text-element>

			<v-text-element
				label="↑"
				:is-strong="true"
				section="3"
			></v-text-element>
		</div>

		<div class="site-footer__container site-footer__container--contextual">
			<!-- Section 1 -->
			<v-text-component section="1">
				<v-text-element
					label="["
					section="1"
				></v-text-element>

				<v-text-element
					label="Scroll Down"
					section="1"
				></v-text-element>
				
				<v-text-element
					label="]"
					section="1"
				></v-text-element>
			</v-text-component>

			<!-- Section 3 -->
			<v-text-component section="3">
				<v-text-element
					label="E"
					section="3"
				></v-text-element>
				
				<v-text-element
					label=":"
					section="3"
				></v-text-element>

				<v-text-element
					label="hi@markobijelic.com"
					:is-link-external="true"
					link-value="mailto:hi@markobijelic.com"
					section="3"
				></v-text-element>
			</v-text-component>
			
			<v-text-component section="3">
				<v-text-element
					label="T"
					section="3"
				></v-text-element>
				
				<v-text-element
					label=":"
					section="3"
				></v-text-element>

				<v-text-element
					label="+1 244 730 586"
					:is-link-external="true"
					link-value="tel:+1244730586"
					section="3"
				></v-text-element>
			</v-text-component>

			<v-text-element
				label="LinkedIn"
				:is-link-external="true"
				link-value="https://www.linkedin.com"
				section="3"
			></v-text-element>
		</div>

		<div class="site-footer__container site-footer__container--copyright">
			<v-text-element
				label="&copy;2023"
			></v-text-element>
		</div>
	</footer>
</template>

<script>
import vTextElement from "@/components/ui/TextElement"
import vTextComponent from "@/components/ui/TextComponent"

export default {
	name: "SiteFooter",

	components: {
		vTextElement,
		vTextComponent
	}
}
</script>

<style lang="postcss">
.site-footer {
	position: fixed;
	bottom: 0;
	width: 100vw;
	height: 0;

	&__container {
		position: absolute;
		display: flex;
		top: calc( var( --layout-row ) * -2 );

		@media ( max-width: 959px ) {
			top: calc( var( --layout-row ) * -1 );
		}

		@media ( max-width: 479px ) {
			top: calc( var( --layout-row ) * -2 );
		}

		&--scroll-arrow {
			width: calc( var( --layout-column ) * 2 );
			justify-content: center;

			@media ( max-width: 959px ) {
			}

			@media ( max-width: 479px ) {
				display: none;
			}
		}

		&--contextual {
			left: calc( var( --layout-column ) * 4 );
			gap: 0 calc( var( --grid-tile ) * 4 );

			@media ( max-width: 959px ) {
				left: calc( var( --layout-column ) * 2.5 );
				gap: 0 calc( var( --grid-tile ) * 1.5 );
			}

			@media ( max-width: 479px ) {
				flex-flow: column nowrap;
				justify-content: flex-end;
				left: calc( var( --layout-column ) * 1 );
				gap: calc( var( --grid-tile ) * 2 ) 0;
				top: unset;
				bottom: calc( ( var( --layout-row ) * 2 ) - ( 0.75rem * 1.5 ) );
			}
		}

		&--copyright {
			right: calc( var( --layout-column ) * 1 );
		}
	}
}
</style>