<template>
	<figure :class="`bg-visuals bg-visuals--${ location }`" ref="bg-visuals-parent"></figure>
</template>

<script>
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { CustomEase } from "gsap/CustomEase"
import hoverEffect from "../../hover-effect.js"

gsap.registerPlugin( ScrollTrigger, CustomEase )

export default {
	name: "BgVisuals",

	props: {
		location: {
			type: String,
			required: true
		},

		image1: {
			type: String,
			required: true
		},

		image2: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			bodyScrollTrigger: null,
			visualsElement: null,
			options: {
				image1: require( `../assets/images/${ this.image1 }-03_a.jpg` ),
				image2: require( `../assets/images/${ this.image2 }-03_a.jpg` ),
				displacementImage: require( "../assets/images/slider-displacement-04.jpg" ),
				imagesRatio: function() {
					if( window.innerWidth <= 479 ) return 1 / 1
					else if( window.innerWidth < window.innerHeight ) return 16 / 9
					else return 9 / 16
				}(),
				angle: this.location === "front" ? Math.PI / 1.33 * -3 : Math.PI / 1.33,
				intensity: 0.25 / 0.5,
				speedIn: 2,
				speedOut: 2,
				hover: false,
				easing: "none"
			}
		}
	},

	methods: {
		createVisualsElement() {
			this.visualsElement = new hoverEffect( {
				parent: this.$refs[ "bg-visuals-parent" ],
				...this.options
			} )
		},

		createOpeningAnimation( duration ) {
			if( this.visualsElement === null ) this.createVisualsElement()

			return gsap.timeline( {
				id: "opening-visuals"
			} )

				.add( this.visualsElement.next( "expo", duration ) )

				.set( this.$refs[ "bg-visuals-parent" ], {
					display: "none"
				} )
		},

		createBodyLeaveAnimation( duration ) {
			if( this.visualsElement === null ) this.createVisualsElement()

			return gsap.timeline( {
				id: "body-visuals-leave"
			} )

				.set( this.$refs[ "bg-visuals-parent" ], {
					visibility: "unset"
				} )

				.add( this.visualsElement.next( "myInPower1OutPower3", duration ) )
		},

		createBodyEnterAnimation( duration ) {
			if( this.visualsElement === null ) this.createVisualsElement()

			return gsap.timeline( {
				id: "body-visuals-enter"
			} )

				.set( this.$refs[ "bg-visuals-parent" ], {
					visibility: "unset"
				} )

				.add( this.visualsElement.previous( "myInPower1OutPower3", duration ) )

				.set( this.$refs[ "bg-visuals-parent" ], {
					visibility: "hidden"
				} )
		},

		createBodyOnScrollAnimation() {
			if( this.visualsElement === null ) this.createVisualsElement()

			return gsap.timeline( {
				id: "body-visuals-on-scroll",
				scrollTrigger: {
					trigger: ".body-content",
					start: "top top",
					end: "bottom bottom",
					scrub: true
				}
			} )

				.add( this.visualsElement.next() )
		}
	},

	mounted() {
		CustomEase.create( "myInPower1OutPower3", "M0,0 C0.184,0 0.328,0.702 0.508,0.882 0.597,0.971 0.734,1 1,1 " )
		if( this.location === "body" )this.createBodyOnScrollAnimation()
	}
}
</script>

<style lang="postcss">
.bg-visuals {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
</style>