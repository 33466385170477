<template>
	<div :class="`cover-content cover-content--${ side }`" ref="cover">
		<h2 class="cover-content__title type__display--medium" ref="title">
			<span>{{ firstLine }}</span>
			<span>{{ secondLine }}</span>
		</h2>
	</div>
</template>

<script>
import { gsap } from "gsap"
import { SplitText } from "gsap/SplitText"

gsap.registerPlugin( SplitText )

export default {
	name: "CoverContent",

	props: {
		side: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			firstLine: this.side === "front" ? "Digital product" : "Got an idea?",
			secondLine: this.side === "front" ? "design & experience" : "Let’s talk about it.",
			lines: null
		}
	},

	methods: {
		splitToLines() {
			this.lines = new SplitText( this.$refs.title.querySelectorAll( "span" ), {
				type: "lines",
				linesClass: "title-line"
			} )
		},

		createEnterAnimation() {
			let targets

			if( window.innerWidth <= 479 ) { // Check if it's small screen, therefor likely a mobile phone
				if( !this.lines ) this.splitToLines()
				targets = this.lines.lines

			} else {
				targets = this.$refs.cover.querySelectorAll( "span" )
			}

			return gsap.timeline( {
				id: `cover-content-${ this.side }-enter`
			} )

				.set( this.$refs.cover, {
					visibility: "unset"
				} )

				.set( targets, {
					width: "fit-content",
					clipPath: "polygon(0% 10%, 0% 10%, 0% 80%, 0% 80%)",
					backgroundImage: "linear-gradient( 90deg, rgba( 255, 255, 255, 0 ) 0%, rgba( 255, 255, 255, 1 ) 0% )"
				} )

				.to( targets, {
					clipPath: "polygon(0% 10%, 100% 10%, 100% 80%, 0% 80%)",
					// duration: gsap.utils.wrap( [ 0.667, 0.5 ] ),
					duration: gsap.utils.distribute( {
						base: 0.5,
						amount: 0.167,
						from: "end"
					} ),
					ease: "power3.in",
					stagger: {
						amount: 0.25
					}
				} )

				.to( targets, {
					backgroundImage: "linear-gradient( 90deg, rgba( 255, 255, 255, 0 ) 101%, rgba( 255, 255, 255, 1 ) 100% )",
					// duration: gsap.utils.wrap( [ 0.75, 1 ] ),
					duration: gsap.utils.distribute( {
						base: 0.75,
						amount: 0.25,
						from: "start"
					} ),
					ease: "power4",
					stagger: {
						amount: 0.125
					}
				}, 0.5 )
		},

		createLeaveAnimation() {
			let targets

			if( window.innerWidth <= 479 ) { // Check if it's small screen, therefor likely a mobile phone
				if( !this.lines ) this.splitToLines()
				targets = this.lines.lines

			} else {
				targets = this.$refs.cover.querySelectorAll( "span" )
			}

			return gsap.timeline( {
				id: `cover-content-${ this.side }-leave`
			} )

				.set( this.$refs.cover, {
					visibility: "unset"
				} )

				.set( targets, {
					width: "fit-content",
					clipPath: "polygon(0% 10%, 100% 10%, 100% 80%, 0% 80%)",
					backgroundImage: "linear-gradient( 90deg, rgba( 255, 255, 255, 1 ) 0%, rgba( 255, 255, 255, 0 ) 0% )"
				} )

				.to( targets, {
					backgroundImage: "linear-gradient( 90deg, rgba( 255, 255, 255, 1 ) 101%, rgba( 255, 255, 255, 0 ) 100% )",
					// duration: gsap.utils.wrap( [ 0.75, 0.5 ] ),
					duration: gsap.utils.distribute( {
						base: 0.5,
						amount: 0.25,
						from: "end"
					} ),
					ease: "power2.in",
					stagger: {
						amount: 0.375
					}
				} )

				.to( targets, {
					clipPath: "polygon(100% 10%, 100% 10%, 100% 80%, 100% 80%)",
					// duration: gsap.utils.wrap( [ 0.5, 0.5 ] ),
					duration: 0.5,
					ease: "power3",
					stagger: {
						amount: 0.125
					}
				}, 0.875 - 0.25 )

				.set( this.$refs.cover, {
					visibility: "hidden"
				} )
		}
	}
}
</script>

<style lang="postcss">
.cover-content {
	position: absolute;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: flex-start;
	align-items: center;

	&__title {
		position: relative;
		margin-left: calc( var( --layout-column ) * 4 );
		margin-right: calc( var( --layout-column ) * 3 );
		margin-top: 6rem; /* line-height of the title, to center the first line vertically */

		@media ( max-width: 959px ) {
			margin-top: unset;
			margin-left: calc( var( --layout-column ) * 2.5 );
			margin-right: calc( var( --layout-column ) * 2.5 );
		}

		@media ( max-width: 479px ) {
			margin: 0 calc( var( --layout-column ) * 1 );
		}

		span {
			display: block;
			position: relative;
			white-space: nowrap;

			@media ( max-width: 959px ) {
			}

			@media ( max-width: 479px ) {
				white-space: unset;
			}

			&:first-child {
				margin-left: calc( var( --layout-column ) * -1.5 );

				@media ( max-width: 959px ) {
					margin-left: calc( var( --layout-column ) * -1 );
				}

				@media ( max-width: 479px ) {
					margin-left: unset;
				}
			}
		}
	}
}
</style>