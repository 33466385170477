<template>
	<div class="site-preloader">
		<span class="site-preloader__progress-value type__label--medium">{{ animatedProgressValue.toFixed( 0 ).toString().padStart( 3, '0' ) }}%</span>
	</div>
</template>

<script>
import { gsap } from "gsap"

export default {
	name: "SitePreloader",

	props: {
		trickle: {
			type: Boolean,
			default: true
		},

		trickleSpeed: {
			type: Number,
			default: 1
		},

		minimum: {
			type: Number,
			default: 1
		},

		maximum: {
			type: Number,
			default: 98
		},

		label: {
			type: String,
			default: null
		},

		modifier: {
			type: Number,
			default: 5
		},

		isSiteLoaded: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			// isSiteLoading: true,
			isRunning: false,
			progressValue: 0.01,
			setProgressInterval: null,
			animatedProgressValue: 0
		}
	},

	watch: {
		isSiteLoaded( newValue, oldValue ) {
			if( newValue && !oldValue ) {
				this.done()
			}
		},

		progressValue( newValue ) {
			gsap.to( this.$data, {
				animatedProgressValue: newValue,
				duration: this.trickleSpeed
			} )
		}
	},

	methods: {
		start() {
			if( !this.isRunning ) {
				this.isRunning = true
				this.setProgressInterval = gsap.to( {}, {
					onStart: this.getIncrementValue,
					duration: this.trickleSpeed,
					repeat: -1
				} )
			}
		},

		pause() {
			if( this.isRunning ) {
				this.isRunning = false
				this.clearProgressInterval()
			}
		},

		done() {
			if( this.isRunning ) {
				this.isRunning = false
				this.clearProgressInterval()
			}
			this.progressValue = 100
		},

		reset() {
			if( this.isRunning ) {
				this.isRunning = false
				this.clearProgressInterval()
			}
			this.progressValue = 0
		},

		updateProgressValue( value ) {
			if( this.progressValue + value > this.maximum ) {
				this.progressValue = this.maximum

			} else if( this.progressValue + value < this.minimum ) {
				this.progressValue = this.minimum

			} else {
				this.progressValue += value
			}
		},

		clearProgressInterval() {
			this.setProgressInterval.kill()
			this.setProgressInterval = null
		},

		getIncrementValue() {
			if( this.progressValue < 100 ) {

				switch( true ) {
					case( this.progressValue >= 0 && this.progressValue < 25 ):
						this.updateProgressValue( Math.random() * ( 3 * this.modifier ) + ( 3 * this.modifier ) )
						break

					case( this.progressValue >= 25 && this.progressValue < 50 ):
						this.updateProgressValue( Math.random() * ( 3 * this.modifier ) )
						break

					case( this.progressValue >= 50 && this.progressValue < 85 ):
						this.updateProgressValue( Math.random() * ( 2 * this.modifier ) )
						break

					case( this.progressValue >= 85 && this.progressValue <= this.maximum ):
						this.updateProgressValue( ( 0.5 * this.modifier ) )
						break

					default:
						this.updateProgressValue( 0 )
						this.clearProgressInterval()
				}
			}
		}
	},

	mounted() {
		this.start()
	}
}
</script>

<style lang="postcss">
.site-preloader {
	z-index: var( --z-index-site-preloader );
	position: fixed;
	width: calc( var( --layout-column ) * 2 );
	height: calc( var( --layout-row ) * 2 );
	display: flex;
	justify-content: center;
	align-items: flex-end;

	@media ( max-width: 959px ) {
		height: calc( var( --layout-row ) * 1 );
	}

	@media ( max-width: 479px ) {
		height: calc( var( --layout-row ) * 2 );
		padding-left: calc( var( --layout-column ) * 1 );
		justify-content: flex-start;
	}

	&__progress-value {
		position: relative;
		font-size: 0.859375rem;
		font-weight: 600;

		@media ( max-width: 959px ) {
			font-size: 0.609375rem;
		}

		@media ( max-width: 479px ) {
			font-size: calc( 0.859375rem * 2 );
		}
	}
}
</style>