<template>
	<v-site-header></v-site-header>
	<v-site-footer></v-site-footer>
	<v-scroll-progress-bar
		v-if="!isSizeMobile"
		ref="scroll-progress-bar"
	></v-scroll-progress-bar>

	<v-side-nav
		v-if="!isSizeMobile"
		ref="side-nav"
	></v-side-nav>
</template>

<script>
import { gsap } from "gsap"

import { useTextElementAnimations } from "@/composables/textElementAnimations.js"

import vSiteHeader from "@/components/ui/SiteHeader"
import vSiteFooter from "@/components/ui/SiteFooter"
import vScrollProgressBar from "@/components/ui/ScrollProgressBar"
import vSideNav from "@/components/ui/SideNav"

export default {
	name: "UiContainer",

	components: {
		vSiteHeader,
		vSiteFooter,
		vScrollProgressBar,
		vSideNav
	},

	props: {
		currentSection: {
			type: Number,
			required: true
		},

		isSizeMobile: {
			type: Boolean,
			required: true
		}
	},

	setup() {
		const { textElementEnter, textElementLeave, textElementEnterSubtle, textElementLeaveSubtle } = useTextElementAnimations()

		return { textElementEnter, textElementLeave, textElementEnterSubtle, textElementLeaveSubtle }
	},

	data() {
		return {
			mainTimeline: null
		}
	},

	methods: {
		createOpeningTimeline() {
			return gsap.timeline( {
				onComplete: () => this.mainTimeline.pause()
			} )
				.add( this.textElementEnter( ".logo-type" ) )
				.set( ".text-component[ data-section='1' ], .text-component[ data-section='global' ]", {
					delay: 2.625,
					display: "flex"
				} )
				.add( this.textElementEnter( ".text-element[ data-section='1' ], .text-element[ data-section='global' ]" ) )
		},

		createLeaveEnterTimeline( from, to ) {
			const tl = gsap.timeline( {
				onComplete: () => this.mainTimeline.pause()
			} )
				.add( this.textElementLeaveSubtle( `.text-element[ data-section="${ from }" ]` ) )
				.set( `.text-component[ data-section="${ from }" ]`, {
					display: "none"
				} )
				.set( `.text-component[ data-section="${ to }" ]`, {
					display: "flex"
				} )
				.add( this.textElementEnterSubtle( `.text-element[ data-section="${ to }" ]` ) )

			if( !this.isSizeMobile ) {
				tl.add( this.$refs[ "scroll-progress-bar" ][ to === "2" ? "enterAnimation" : "leaveAnimation" ](), to === "2" ? ">-=0.5" : 0 )
				tl.to( this.$refs[ "side-nav" ].$refs[ "side-nav" ], {
					opacity: () => to === "2" ? 1 : 0,
				}, "<" )
			}

			return tl
		},

		createMainTimeline() {
			return gsap.timeline( {
				id: "main-timeline-ui"
			} )

				.set( ".logo-type, .text-component, .text-element", {
					display: "none"
				} )

				.set( ".side-nav", {
					opacity: 0
				} )

				.addPause( "+=0.001" )

				.addLabel( "opening" )
				.add( this.createOpeningTimeline() )

				.addLabel( "enter-section-2" )
				.add( this.createLeaveEnterTimeline( "1", "2" ) )

				.addLabel( "leave-section-2" )
				.add( this.createLeaveEnterTimeline( "2", "3" ) )

				.addLabel( "enter-back-section-2" )
				.add( this.createLeaveEnterTimeline( "3", "2" ) )

				.addLabel( "leave-back-section-2" )
				.add( this.createLeaveEnterTimeline( "2", "1" ) )
		}
	},

	mounted() {
		this.$nextTick( () => {
			this.mainTimeline = this.createMainTimeline()
		} )
	}
}
</script>