import { gsap } from "gsap"

export function useTextElementAnimations() {
	function textElementEnter( target ) {
		return gsap.timeline()
			.set( target, {
				// clearProps: "display",
				display: ( index, targetElement ) => targetElement === ".text-element" ? "inline-block" : "flex",
				width: "fit-content",
				clipPath: "polygon( 0% 0%, 0% 0%, 0% 100%, 0% 100% )",
				backgroundImage: "linear-gradient( 90deg, rgba( 255, 255, 255, 0 ) 0%, rgba( 255, 255, 255, 1 ) 0% )"
			} )

			.to( target, {
				clipPath: "polygon( 0% 0%, 100% 0%, 100% 100%, 0% 100% )",
				duration: 0.25,
				ease: "power3",
				stagger: {
					amount: 0.25
				}
			} )

			.to( target, {
				backgroundImage: "linear-gradient( 90deg, rgba( 255, 255, 255, 0 ) 100%, rgba( 255, 255, 255, 1 ) 101% )",
				duration: 0.5,
				ease: "power4",
				stagger: {
					amount: 0.25
				}
			}, "<10%" )

			.set( target, {
				backgroundImage: "linear-gradient( 90deg, rgba( 255, 255, 255, 0 ) 100%, rgba( 255, 255, 255, 0 ) 101% )",
				clearProps: "clip-path, background-image"
			} )
	}

	function textElementEnterSubtle( target ) {
		return gsap.timeline()
			.set( target, {
				// clearProps: "display",
				display: ( index, targetElement ) => targetElement === ".text-element" ? "inline-block" : "flex",
				width: "fit-content",
				clipPath: "polygon( 0% 0%, 0% 0%, 0% 100%, 0% 100% )"
			} )

			.to( target, {
				clipPath: "polygon( 0% 0%, 100% 0%, 100% 100%, 0% 100% )",
				duration: 0.5,
				ease: "power3",
				stagger: {
					amount: 0.25
				}
			} )

			.set( target, {
				clearProps: "clip-path"
			} )
	}

	function textElementLeave( target ) {
		return gsap.timeline()
			.set( target, {
				// clearProps: "display",
				display: ( index, targetElement ) => targetElement === ".text-element" ? "inline-block" : "flex",
				width: "fit-content",
				clipPath: "polygon( 0% 0%, 100% 0%, 100% 100%, 0% 100% )",
				backgroundImage: "linear-gradient( 90deg, rgba( 255, 255, 255, 1 ) 0%, rgba( 255, 255, 255, 0 ) 0% )"
			} )

			.to( target, {
				backgroundImage: "linear-gradient( 90deg, rgba( 255, 255, 255, 1 ) 100%, rgba( 255, 255, 255, 0 ) 101% )",
				duration: 0.5,
				ease: "power4.in",
				stagger: {
					amount: 0.25
				}
			} )

			.to( target, {
				clipPath: "polygon( 100% 0%, 100% 0%, 100% 100%, 100% 100% )",
				duration: 0.25,
				ease: "power3.in",
				stagger: {
					amount: 0.25
				}
			}, "<+=0.3125" )

			.set( target, {
				display: "none",
				clearProps: "clip-path, background-image"
			} )
	}

	function textElementLeaveSubtle( target ) {
		return gsap.timeline()
			.set( target, {
				// clearProps: "display",
				display: ( index, targetElement ) => targetElement === ".text-element" ? "inline-block" : "flex",
				width: "fit-content",
				clipPath: "polygon( 0% 0%, 100% 0%, 100% 100%, 0% 100% )"
			} )

			.to( target, {
				clipPath: "polygon( 100% 0%, 100% 0%, 100% 100%, 100% 100% )",
				duration: 0.5,
				ease: "power3.in",
				stagger: {
					amount: 0.25
				}
			} )

			.set( target, {
				display: "none",
				clearProps: "clip-path"
			} )
	}

	return { textElementEnter, textElementLeave, textElementEnterSubtle, textElementLeaveSubtle }
}