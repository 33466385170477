<template>
	<div class="text-component" :data-section="section">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: "TextComponent",

	props: {
		section: {
			type: String,
			default: "global"
		}
	},
}
</script>

<style lang="postcss">
.text-component {
	display: flex;
	gap: 0 calc( var( --grid-tile ) * 1 );

	@media ( max-width: 959px ) {
		gap: 0 calc( var( --grid-tile ) * 0.5 );
	}

	@media ( max-width: 479px ) {
		gap: 0 calc( var( --grid-tile ) * 1 );
	}
}
</style>