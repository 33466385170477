<template>
	<div class="scroll-progress-bar">
		<div class="scroll-progress-bar__value"></div>
	</div>
</template>

<script>
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin( ScrollTrigger )

export default {
	name: "ScrollProgressBar",

	data() {
		return {
			trackProgressAnimation: null
		}
	},

	methods: {
		enterAnimation() {
			return gsap.timeline()
				.to( ".scroll-progress-bar", {
					transformOrigin: "top",
					scaleY: 1,
					ease: "power3",
					duration: 0.5
				} )
		},

		leaveAnimation() {
			return gsap.timeline()
				.to( ".scroll-progress-bar", {
					transformOrigin: "bottom",
					scaleY: 0,
					ease: "power3.in",
					duration: 0.5
				} )
		},

		createTrackProgressAnimation() {
			this.trackProgressAnimation = gsap.timeline()
				.to( ".scroll-progress-bar__value", {
					scaleY: 1,
					ease: "none",
					scrollTrigger: {
						trigger: ".body-content",
						start: "top top",
						end: "bottom bottom",
						scrub: 1
					}
				} )
		}
	},

	mounted() {
		this.createTrackProgressAnimation()
	}
}
</script>

<style lang="postcss">
.scroll-progress-bar {
	position: fixed;
	left: calc( var( --layout-column ) * 2 );
	top: 0;
	width: var( --line-thin );
	height: 100vh;
	background-color: rgba( var( --color ), 0.125 );
	transform-origin: top;
	transform: scaleY( 0 );

	@media ( max-width: 959px ) {
	}

	@media ( max-width: 479px ) {
		left: 0;
		width: calc( var( --line-regular ) * 2 );
	}

	&__value {
		position: relative;
		width: 100%;
		height: 100%;
		background-color: rgba( var( --color ), 0.125 );
		transform-origin: top;
		transform: scaleY( 0 );
	}
}
</style>