<template>
	<main class="body-content" ref="body-content">
		<section class="body-content__section" ref="first-section">
			<p class="type__body--large">I’m Marko Bijelić — a digital product designer, best known for collaborations with tech billionaires.</p>
		</section>

		<section class="body-content__section">
			<p class="type__body--large">The list includes:</p>
			<ul class="body-content__unordered-list type__body--large">
				<li class="body-content__unordered-list-item">Naval Ravikant</li>
				<li class="body-content__unordered-list-item">Tim Ferriss</li>
				<li class="body-content__unordered-list-item">Peter Thiel</li>
				<li class="body-content__unordered-list-item">Nick Chong <span class="body-content__unordered-list-item-detail type__superscript">Zoom</span></li>
				<li class="body-content__unordered-list-item">Chris Wanstrath <span class="body-content__unordered-list-item-detail type__superscript">GitHub</span></li>
				<li class="body-content__unordered-list-item">Sid Sijbrandij <span class="body-content__unordered-list-item-detail type__superscript">GitLab</span></li>
				<li class="body-content__unordered-list-item">Eren Bali & Gagan Biyani <span class="body-content__unordered-list-item-detail type__superscript">Udemy</span></li>
				<li class="body-content__unordered-list-item">Bryant Chou <span class="body-content__unordered-list-item-detail type__superscript">WebFlow</span></li>
				<li class="body-content__unordered-list-item">Grant Haas <span class="body-content__unordered-list-item-detail type__superscript">Haas Portman Family Office</span></li>
				<li class="body-content__unordered-list-item">Hiten Shah</li>
				<li class="body-content__unordered-list-item">Neil Patel</li>
			</ul>
		</section>

		<section class="body-content__section" ref="last-section">
			<p class="type__body--large">I welcome you in Fall & Winter of 2023—24, to meet me in Valencia, Spain and Dubai, UAE.</p>
		</section>
	</main>
</template>

<script>
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { SplitText } from "gsap/SplitText"

gsap.registerPlugin( ScrollTrigger, SplitText )

export default {
	name: "BodyContent",

	data() {
		return {
			splitTextWrapper: null,
			splitTextContent: [],
			splitTextWrapperClass: "split-lines-wrapper",
			splitTextContentClass: "split-lines-content"
		}
	},

	methods: {
		splitter( target, type, className ) {
			return new SplitText( target, {
				type: type,
				linesClass: className
			} )
		},

		splitTheText() {
			if( this.splitTextWrapper === null ) this.splitTextWrapper = this.splitter( ".body-content__section p, .body-content__section ul", "lines", this.splitTextWrapperClass )
			if( !this.splitTextContent.length ) this.splitTextWrapper.lines.forEach( ( line ) => this.splitTextContent.push( this.splitter( line, "lines", this.splitTextContentClass ) ) )
		},

		createOnScrollAnimation() {
			this.splitTheText()
			return this.splitTextContent.forEach( ( split, index ) => this.createLineOnScrollAnimation( split.lines[ 0 ], index ) )
		},

		createLineOnScrollAnimation( line, index ) {
			this.splitTheText()
			return gsap.timeline( {
				id: `line-on-scroll-${ index }`
			} )

				.addLabel( `line-on-scroll-${ index }-enter-start` )

				.set( line, {
					width: "fit-content",
					backgroundClip: "text",
					"-webkit-background-clip": "text",
					color: "transparent",
					"-webkit-text-fill-color": "transparent"
				} )

				.fromTo( line, {
					backgroundImage: "linear-gradient( 90deg, rgba( 255, 255, 255, 1 ) 0%, rgba( 255, 255, 255, 0.125 ) 0% )"
				}, {
					backgroundImage: "linear-gradient( 90deg, rgba( 255, 255, 255, 1 ) 100%, rgba( 255, 255, 255, 0.125 ) 100% )",
					ease: "linear",
					scrollTrigger: {
						id: `line-on-scroll-${ index }-enter`,
						trigger: line,
						start: "top 100%",
						end: "bottom 85%",
						scrub: 0.5
						}
				} )

				.addLabel( `line-on-scroll-${ index }-enter-end` )

				.addLabel( `line-on-scroll-${ index }-leave-start` )

				.fromTo( line, {
					backgroundImage: "linear-gradient( 90deg, rgba( 255, 255, 255, 0.125 ) 0%, rgba( 255, 255, 255, 1 ) 0% )"
				}, {
					backgroundImage: "linear-gradient( 90deg, rgba( 255, 255, 255, 0.125 ) 100%, rgba( 255, 255, 255, 1 ) 100% )",
					ease: "linear",
					scrollTrigger: {
						id: `line-on-scroll-${ index }-leave`,
						trigger: line,
						start: "top 15%",
						end: "bottom 0%",
						scrub: 0.5
						}
				} )

				.addLabel( `line-on-scroll-${ index }-leave-end` )
		},

		createEnterAnimation( target, event ) {
			this.splitTheText()
			return gsap.timeline( {
				id: `body-content-${ event }`
			} )

				.set( this.$refs[ "body-content" ], {
					visibility: "unset"
				} )

				.set( target.querySelectorAll( ".split-lines-wrapper" ), {
					width: "fit-content",
					clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
					backgroundImage: "linear-gradient( 90deg, rgba( 255, 255, 255, 0 ) 0%, rgba( 255, 255, 255, 1 ) 0% )"
				} )

				.to( target.querySelectorAll( ".split-lines-wrapper" ), {
					clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
					duration: gsap.utils.distribute( {
						base: 0.5,
						amount: 0.167,
						from: "end"
					} ),
					ease: "power3.in",
					stagger: {
						amount: 0.25
					}
				} )

				.to( target.querySelectorAll( ".split-lines-wrapper" ), {
					backgroundImage: "linear-gradient( 90deg, rgba( 255, 255, 255, 0 ) 101%, rgba( 255, 255, 255, 1 ) 100% )",
					duration: gsap.utils.distribute( {
						base: 0.75,
						amount: 0.25,
						from: "start"
					} ),
					ease: "power4",
					stagger: {
						amount: 0.125
					}
				}, 0.5 )
		},

		createLeaveAnimation( target, event ) {
			this.splitTheText()
			return gsap.timeline( {
				id: `body-content-${ event }`
			} )

				.set( this.$refs[ "body-content" ], {
					visibility: "unset"
				} )

				.set( target.querySelectorAll( ".split-lines-wrapper" ), {
					width: "fit-content",
					clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
					backgroundImage: "linear-gradient( 90deg, rgba( 255, 255, 255, 1 ) 0%, rgba( 255, 255, 255, 0 ) 0% )"
				} )

				.to( target.querySelectorAll( ".split-lines-wrapper" ), {
					backgroundImage: "linear-gradient( 90deg, rgba( 255, 255, 255, 1 ) 101%, rgba( 255, 255, 255, 0 ) 100% )",
					duration: gsap.utils.distribute( {
						base: 0.375,
						amount: 0.75 - 0.357,
						from: "end"
					} ),
					stagger: {
						amount: 0.55
					},
					ease: "power2.in"
				} )

				.to( target.querySelectorAll( ".split-lines-wrapper" ), {
					clipPath: "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)",
					duration: 0.2,
					stagger: {
						amount: 0.375
					},
					ease: "power1.in"
				}, "<0.5625" )

				.set( this.$refs[ "body-content" ], {
					visibility: "hidden"
				} )
		}
	}
}
</script>

<style lang="postcss">
.body-content {
	position: relative;

	&__section {
		position: relative;
		min-height: 100vh;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		flex-flow: column nowrap;
		padding: calc( var( --layout-row ) * 4 ) calc( var( --layout-column ) * 4 );

		/* Hack from GSAP's official docs, for avoiding incorrect line breaks */
		font-kerning: none;
		-webkit-text-rendering: optimizeSpeed;
		text-rendering: optimizeSpeed;
		-webkit-transform: translateZ( 0 );
		transform: translateZ( 0 );

		@media ( max-width: 959px ) {
			padding: calc( var( --layout-row ) * 2 ) calc( var( --layout-column ) * 2.5 );
		}

		@media ( max-width: 479px ) {
			padding: calc( var( --layout-row ) * 2 ) calc( var( --layout-column ) * 1 );
		}
	}

	&__section:not( :last-of-type )&__section:not( :first-of-type ) {
		padding-top: calc( var( --layout-row ) * 6 );
		padding-bottom: calc( var( --layout-row ) * 6 );
	}

	&__section .type__body--large:not( :only-child ) {
		margin-bottom: calc( var( --layout-row ) * 2 );
	}

	&__unordered-list-item {
		@media ( max-width: 959px ) {
			padding-left: calc( var( --layout-column ) * 1 );
			text-indent: calc( var( --layout-column ) * -1 );
		}

		@media ( max-width: 479px ) {
		}
	}
	
	&__unordered-list-item::before {
		content: "–";
		display: inline-block;
		margin-right: calc( var( --grid-tile ) * 1 );

		@media ( max-width: 959px ) {
			padding-left: calc( var( --layout-column ) * 1 - var( --grid-tile ) );
			text-indent: calc( var( --layout-column ) * -1 + var( --grid-tile ) );
		}

		@media ( max-width: 479px ) {
		}
	}

	&__unordered-list-item-detail::before {
		content: "[";
	}

	&__unordered-list-item-detail::after {
		content: "]";
	}

	p .split-lines-content {
		@media ( max-width: 959px ) {
			white-space: nowrap;
		}

		@media ( max-width: 479px ) {
		}
	}
}
</style>