<template>
	<figure class="logo-type">
		<svg class="logo-type__graphic" x="0px" y="0px" viewBox="0 0 69.05 19.26">
			<g>
				<path class="st0" d="M3.01,19.26V0.7l3.94,18.56h5.84L16.76,0.7v18.56h3.01V0h-5.91L9.72,18.53L5.72,0H0v19.26H3.01z"/>
				<path class="st0" d="M51.15,12.54V9.86H23.59v2.69H51.15z"/>
				<path class="st0" d="M63.19,19.26c1.89,0,3.34-0.48,4.34-1.45c1.01-0.97,1.51-2.36,1.51-4.17c0-1.13-0.22-2.06-0.66-2.79 s-1.02-1.28-1.73-1.65c1.12-0.86,1.68-2.13,1.68-3.81c0-1.57-0.49-2.85-1.46-3.86C65.91,0.5,64.65,0,63.1,0h-8.11v19.26H63.19z M58.05,10.84h4.73c1.01,0,1.78,0.24,2.31,0.71c0.53,0.47,0.79,1.17,0.79,2.08c0,0.92-0.27,1.61-0.8,2.07 c-0.53,0.46-1.3,0.69-2.3,0.69h-4.73V10.84z M58.05,2.86h4.26c0.94,0,1.65,0.23,2.14,0.7c0.48,0.47,0.73,1.07,0.73,1.81 c0,1.77-0.95,2.66-2.86,2.66h-4.26V2.86z"/>
			</g>
		</svg>
	</figure>
</template>

<script>
export default {
	name: "LogoType"
}
</script>

<style lang="postcss">
.logo-type {
	position: relative;
	display: flex;

	&__graphic {
		position: relative;
		width: calc( var( --grid-tile ) * 2.15625 );
		fill: rgba( var( --color ), 1 );
		padding: calc( var( --grid-tile ) / 8 ) 0;

		@media ( max-width: 959px ) {
			width: 1.53125rem;
		}

		@media ( max-width: 479px ) {
			width: calc( 2.15625rem * 2 );
		}
	}
}
</style>