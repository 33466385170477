<template>
	<div
		v-if="isLinkExternal"
		class="text-element text-element--is-link-external type__label--medium"
		:data-section="section"
		ref="element"
		@mouseenter="onMouseEnterAnimation"
		@mouseleave="onMouseLeaveAnimation"
	>
		<a
			class="text-element__content"
			:href="linkValue"
			target="_blank"
		>{{ label }}</a>
	</div>
	
	<div
		v-else-if="isLinkLocal"
		class="text-element text-element--is-link-local type__label--medium"
		:data-section="section"
		ref="element"
		@mouseenter="onMouseEnterAnimation"
		@mouseleave="onMouseLeaveAnimation"
	>
		<span
			class="text-element__content"
		>{{ label }}</span>
	</div>
	
	<div
		v-else
		class="text-element type__label--medium"
		:class="{ 'text-element--is-strong': isStrong, 'text-element--is-logo': isLogo }"
		:data-section="section"
		ref="element"
	>
		<span
			class="text-element__content"
		>{{ label }}</span>
	</div>
</template>

<script>
import { gsap } from "gsap"

export default {
	name: "TextElement",

	props: {
		label: {
			type: String,
			require: true
		},

		isLinkLocal: {
			type: Boolean,
			default: false
		},

		isLinkExternal: {
			type: Boolean,
			default: false
		},

		linkValue: {
			type: String,
			require: () => this.isLinkExternal ? true : false
		},

		isStrong: {
			type: Boolean,
			default: false
		},

		isLogo: {
			type: Boolean,
			default: false
		},

		section: {
			type: String,
			default: "global"
		}
	},

	methods: {
		onMouseEnterAnimation() {
			const target = this.$refs[ "element" ].querySelector( ".text-element__content" )

			gsap.timeline()

				.set( target, {
					width: "fit-content",
					backgroundClip: "text",
					"-webkit-background-clip": "text",
					color: "transparent",
					"-webkit-text-fill-color": "transparent"
				} )

				.fromTo( target, {
					backgroundImage: "linear-gradient( 90deg, rgba( 255, 255, 255, 1 ) 0%, rgba( 255, 255, 255, 0.5 ) 0% )"
				}, {
					backgroundImage: "linear-gradient( 90deg, rgba( 255, 255, 255, 1 ) 100%, rgba( 255, 255, 255, 0.5 ) 100% )",
					duration: 0.5,
					ease: "power3"
				} )
		},

		onMouseLeaveAnimation() {
			const target = this.$refs[ "element" ].querySelector( ".text-element__content" )

			gsap.timeline()

				.set( target, {
					width: "fit-content",
					backgroundClip: "text",
					"-webkit-background-clip": "text",
					color: "transparent",
					"-webkit-text-fill-color": "transparent"
				} )

				.fromTo( target, {
					backgroundImage: "linear-gradient( 90deg, rgba( 255, 255, 255, 0.5 ) 0%, rgba( 255, 255, 255, 1 ) 0% )"
				}, {
					backgroundImage: "linear-gradient( 90deg, rgba( 255, 255, 255, 0.5 ) 100%, rgba( 255, 255, 255, 1 ) 100% )",
					duration: 0.5,
					ease: "power2"
				} )
		}
	}
}
</script>

<style>
.text-element {
	position: relative;
	display: inline-block;
	color: rgba( var( --color ), 0.5 );

	&__content {
		display: inline-block;
	}

	&--is-link-external {
		cursor: pointer;
	}

	&--is-link-local {
		cursor: pointer;
	}

	&--is-strong {
		color: rgba( var( --color ), 1 );
	}

	&--is-logo {
		font-size: 0.859375rem;
		font-weight: 600;
	}
}
</style>