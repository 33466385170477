<template>
	<v-site-preloader
		v-if="currentSection === 0"
		:is-site-loaded="isSiteLoaded"
	></v-site-preloader>

	<v-main-container
		:style="{ visibility: isSiteLoaded ? 'unset' : 'hidden' }"
		ref="main-container"
		:is-size-tablet="isSizeTablet"
		:is-size-mobile="isSizeMobile"
		:is-orientation-portrait="isOrientationPortrait"
	></v-main-container>

	<v-ui-container
		ref="ui-container"
		:current-section="currentSection"
		:is-size-mobile="isSizeMobile"
	></v-ui-container>
</template>

<script>
import { gsap } from "gsap"
import { Observer } from "gsap/Observer"

gsap.registerPlugin( Observer )

import vSitePreloader from "@/components/SitePreloader"
import vMainContainer from "@/components/MainContainer"
import vUiContainer from "@/components/UiContainer"

export default {
	name: "App",

	components: {
		vSitePreloader,
		vMainContainer,
		vUiContainer
	},

	data() {
		return {
			// isSitePreloaderVisible: true,
			isSiteLoaded: false,
			currentSection: 0,
			isSizeMobile: window.innerWidth <= 479,
			isSizeTablet: window.innerWidth > 479 && window.innerWidth <= 959,
			isOrientationPortrait: window.innerWidth < window.innerHeight,
			mainObserver: Observer.create( {
				target: window,
				type: "wheel,touch",
				wheelSpeed: -1,
				onUp: () => this.handleCurrentSectionUpdate( "next" ),
				onDown: () => this.handleCurrentSectionUpdate( "previous" )
			} ),
			resizeOptions: {
				windowWidth: window.innerWidth,
				delay: 250,
				timeout: false
			}
		}
	},

	watch: {
		currentSection( newValue, oldValue ) {
			if( newValue === 1 && oldValue === 0 ) {
				this.$refs[ "main-container" ].mainTimeline.play( "opening" )
				this.$refs[ "ui-container" ].mainTimeline.play( "opening" )

			} else if( newValue === 2 ) {
				if( oldValue === 1 ) {
					this.$refs[ "main-container" ].mainTimeline.play( "enter" )
					this.$refs[ "ui-container" ].mainTimeline.play( "enter-section-2" )

				} else if( oldValue === 3 ) {
					this.$refs[ "main-container" ].mainTimeline.play( "enter-back" )
					this.$refs[ "ui-container" ].mainTimeline.play( "enter-back-section-2" )
				}

			} else if( newValue === 3 ) {
				this.$refs[ "main-container" ].mainTimeline.play( "leave" )
				this.$refs[ "ui-container" ].mainTimeline.play( "leave-section-2" )

			} else if( newValue === 1 ) {
				this.$refs[ "main-container" ].mainTimeline.play( "leave-back" )
				this.$refs[ "ui-container" ].mainTimeline.play( "leave-back-section-2" )
			}
		},

		isSiteLoaded( newValue, oldValue ) {
			if( newValue && !oldValue ) {
				gsap.delayedCall( 1, this.handleCurrentSectionUpdate, [ "next" ] )
				// gsap.delayedCall( 3.625, () => this.isSitePreloaderVisible = false )
			}
		}
	},

	methods: {
		handleCurrentSectionUpdate( direction ) {
			if( direction === "next" && this.$refs[ "main-container" ].mainTimeline.paused() && this.$refs[ "ui-container" ].mainTimeline.paused() ) {
				switch( this.currentSection ) {
					case 0:
						this.currentSection++
						break

					case 1:
						this.currentSection++
						break

					case 2:
						if( this.$refs[ "main-container" ].scrollSmoother.progress > 0.9975 ) this.currentSection++
						break

					case 3:
						break
				}

			} else if( direction === "previous" && this.$refs[ "main-container" ].mainTimeline.paused() && this.$refs[ "ui-container" ].mainTimeline.paused() ) {
				switch( this.currentSection ) {
					case 3:
						this.currentSection--
						break

					case 2:
					if( this.$refs[ "main-container" ].scrollSmoother.progress < 0.0025 ) this.currentSection--
						break

					case 1:
						break
				}
			}
		},
		
		reloadOnResize() {
			clearTimeout( this.resizeOptions.timeout )
			this.resizeOptions.timeout = setTimeout( () => {
				if( window.innerWidth !== this.resizeOptions.windowWidth ) window.location.reload()
			}, this.resizeOptions.delay )
		}
	},

	mounted() {
		window.addEventListener( "load", () => {
			this.isSiteLoaded = true
		} )

		window.addEventListener( "resize", this.reloadOnResize )
	}
}
</script>

<style>
#app {
	position: relative;
	width: 100%;
	height: 100vh;
}
</style>
