<template>
	<header class="site-header">
		<div class="site-header__container site-header__container--logo">
			<v-logo-type></v-logo-type>
		</div>

		<div class="site-header__container site-header__container--local-nav">
			<v-text-component>
				<v-text-element
					label="01"
					section="1"
				></v-text-element>
				
				<v-text-element
					label="02"
					section="2"
				></v-text-element>
				
				<v-text-element
					label="03"
					section="3"
				></v-text-element>
				
				<v-text-element
					label="/"
				></v-text-element>
				
				<v-text-element
					label="03"
				></v-text-element>
				
				<v-text-element
					label=":"
				></v-text-element>

				<v-text-element
					label="Intro"
					section="1"
				></v-text-element>
				
				<v-text-element
					label="About"
					section="2"
				></v-text-element>
				
				<v-text-element
					label="Contact"
					section="3"
				></v-text-element>
			</v-text-component>
		</div>

		<div class="site-header__container site-header__container--external-nav">
			<v-text-element
				label="LinkedIn"
				:is-link-external="true"
				link-value="https://www.linkedin.com"
			></v-text-element>

			<v-text-element
				label="Email"
				:is-link-external="true"
				link-value="mailto:marko@markobijelic.com"
			></v-text-element>
		</div>
	</header>
</template>

<script>
import vTextElement from "@/components/ui/TextElement"
import vTextComponent from "@/components/ui/TextComponent"
import vLogoType from "@/components/LogoType"

export default {
	name: "SiteHeader",

	components: {
		vTextElement,
		vTextComponent,
		vLogoType
	}
}
</script>

<style lang="postcss">
.site-header {
	position: fixed;
	width: 100vw;
	height: 0;

	&__container {
		position: absolute;
		display: flex;
		bottom: calc( var( --layout-row ) * -2 );

		@media ( max-width: 959px ) {
			bottom: calc( var( --layout-row ) * -1 );
		}

		@media ( max-width: 479px ) {
			bottom: calc( var( --layout-row ) * -2 );
		}

		&--logo {
			width: calc( var( --layout-column ) * 2 );
			justify-content: center;

			@media ( max-width: 959px ) {
			}

			@media ( max-width: 479px ) {
				justify-content: flex-start;
				left: calc( var( --layout-column ) * 1 );
			}
		}

		&--local-nav {
			left: calc( var( --layout-column ) * 4 );

			@media ( max-width: 959px ) {
				left: calc( var( --layout-column ) * 2.5 );
			}

			@media ( max-width: 479px ) {
				display: none;
			}
		}

		&--external-nav {
			right: calc( var( --layout-column ) * 1 );
			gap: 0 calc( var( --grid-tile ) * 1 );
		}
	}
}
</style>