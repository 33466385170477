<template>
	<main class="main-container">
		<div class="main-container__visuals">
			<template v-if="isSizeMobile">
				<v-bg-visuals
					ref="opening-visuals"
					location="front"
					image1="square-capital-blank"
					image2="square-capital-m"
				></v-bg-visuals>

				<v-bg-visuals
					ref="front-cover-visuals"
					location="front"
					image1="square-capital-a"
					image2="square-capital-m"
				></v-bg-visuals>

				<v-bg-visuals
					ref="back-cover-visuals"
					location="back"
					image1="square-capital-k"
					image2="square-capital-o"
				></v-bg-visuals>

				<v-bg-visuals
					ref="body-visuals"
					location="body"
					image1="square-capital-a"
					image2="square-capital-k"
				></v-bg-visuals>
			</template>

			<template v-else-if="!isSizeMobile && isOrientationPortrait">
				<v-bg-visuals
					ref="opening-visuals"
					location="front"
					image1="portrait-capital-blank"
					image2="portrait-titles-front-cover"
				></v-bg-visuals>

				<v-bg-visuals
					ref="front-cover-visuals"
					location="front"
					image1="portrait-capital-m"
					image2="portrait-titles-front-cover"
				></v-bg-visuals>

				<v-bg-visuals
					ref="back-cover-visuals"
					location="back"
					image1="portrait-capital-o"
					image2="portrait-titles-back-cover"
				></v-bg-visuals>

				<v-bg-visuals
					ref="body-visuals"
					location="body"
					image1="portrait-capital-m"
					image2="portrait-capital-o"
				></v-bg-visuals>
			</template>

			<template v-else>
				<v-bg-visuals
					ref="opening-visuals"
					location="front"
					image1="capital-blank"
					image2="titles-front-cover"
				></v-bg-visuals>

				<v-bg-visuals
					ref="front-cover-visuals"
					location="front"
					image1="capital-m"
					image2="titles-front-cover"
				></v-bg-visuals>

				<v-bg-visuals
					ref="back-cover-visuals"
					location="back"
					image1="capital-o"
					image2="titles-back-cover"
				></v-bg-visuals>

				<v-bg-visuals
					ref="body-visuals"
					location="body"
					image1="capital-m"
					image2="capital-o"
				></v-bg-visuals>
			</template>

			<v-bg-overlay-texture></v-bg-overlay-texture>
		</div>

		<div class="main-container__content">
			<v-cover-content
				ref="front-cover-content"
				side="front"
			></v-cover-content>

			<v-cover-content
				ref="back-cover-content"
				side="back"
			></v-cover-content>

			<v-body-content
				ref="body-content"
			></v-body-content>
		</div>
	</main>
</template>

<script>
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { ScrollSmoother } from "gsap/ScrollSmoother"

gsap.registerPlugin( ScrollTrigger, ScrollSmoother )

import vBgVisuals from "@/components/BgVisuals"
import vBgOverlayTexture from "@/components/BgOverlayTexture"
import vCoverContent from "@/components/CoverContent"
import vBodyContent from "@/components/BodyContent"

export default {
	name: "MainContainer",

	components: {
		vBgVisuals,
		vBgOverlayTexture,
		vCoverContent,
		vBodyContent
	},

	props: {
		isSizeTablet: {
			type: Boolean,
			required: true
		},

		isSizeMobile: {
			type: Boolean,
			required: true
		},

		isOrientationPortrait: {
			type: Boolean,
			required: true
		}
	},

	data() {
		return {
			scrollSmoother: null,
			mainTimeline: null
		}
	},

	methods: {
		createScrollSmoother() {
			this.scrollSmoother = ScrollSmoother.create( {
				content: ".body-content",
				smooth: 2,
				speed: window.innerWidth > 959 ? 1 / 2 : 1,
				smoothTouch: true,
				// normalizeScroll: true,
				// onUpdate: self => {
				// 	console.log( "scrollSmoother progress: ", self.progress )
				// }
			} )

			this.scrollSmoother.scrollTop( 0 )
		},

		createMainOnScrollTimeline() {
			this.$refs[ "body-content" ].createOnScrollAnimation()
		},

		createMainBodyEnterAnimation( action ) {
			return gsap.timeline( {
				id: `main-timeline-${ action }`,
				onComplete: () => {
					this.mainTimeline.pause()
				}
			} )

				.add( this.$refs[ `${ action === "enter" ? "front" : "back" }-cover-visuals` ].createBodyEnterAnimation() )
				.set( this.$refs[ "body-visuals" ].$refs[ "bg-visuals-parent" ], {
					visibility: "unset",
					onComplete: () => {
						this.scrollSmoother.paused( false )
					}
				} )
				.add( this.$refs[ `${ action === "enter" ? "front" : "back" }-cover-content` ].createLeaveAnimation(), "-=2" )
				.add( this.$refs[ "body-content" ].createEnterAnimation( this.$refs[ "body-content" ].$refs[ `${ action === "enter" ? "first" : "last" }-section` ], action ), ">-=0.9" )
		},

		createMainBodyLeaveAnimation( action ) {
			return gsap.timeline( {
				id: `main-timeline-${ action }`,
				onStart: () => {
					this.scrollSmoother.paused( true )
				},
				onComplete: () => {
					this.mainTimeline.pause()
				}
			} )

				.set( this.$refs[ "body-visuals" ].$refs[ "bg-visuals-parent" ], {
					visibility: "hidden"
				} )
				.add( this.$refs[ `${ action === "leave" ? "back" : "front" }-cover-visuals` ].createBodyLeaveAnimation() )
				.add( this.$refs[ "body-content" ].createLeaveAnimation( this.$refs[ "body-content" ].$refs[ `${ action === "leave" ? "last" : "first" }-section` ], "leave" ), "<" )
				.add( this.$refs[ `${ action === "leave" ? "back" : "front" }-cover-content` ].createEnterAnimation(), ">-=0.75" )
		},

		createMainTimeline() {
			this.mainTimeline = gsap.timeline( {
				id: "main-timeline"
			} )

				.set( ".main-container__visuals > figure:not( :first-child ), .cover-content, .body-content", {
					visibility: "hidden"
				} )
				.call( this.scrollSmoother.paused, [ true ] )
				.addPause( "+=0.001" )

				// Opening
				.addLabel( "opening" )
				.add( this.$refs[ "opening-visuals" ].createOpeningAnimation( 3 ) )
				.add( this.$refs[ "front-cover-visuals" ].createBodyLeaveAnimation( 0.001 ) ) // Visuals - set front cover visuals to next state, instead of default (previous)
				.add( this.$refs[ "front-cover-content" ].createEnterAnimation(), "-=1" )
				.addPause()

				// Enter
				.addLabel( "enter" )
				.add( this.createMainBodyEnterAnimation( "enter" ) )
				
				// Leave
				.addLabel( "leave" )
				.add( this.createMainBodyLeaveAnimation( "leave" ) )

				// EnterBack
				.addLabel( "enter-back" )
				.add( this.createMainBodyEnterAnimation( "enter-back" ) )

				// LeaveBack
				.addLabel( "leave-back" )
				.add( this.createMainBodyLeaveAnimation( "leave-back" ) )
		}
	},

	mounted() {
		this.$nextTick( () => {
			// console.log( this.$refs[ "body-content"] )
			this.createScrollSmoother()
			if( !this.isSizeMobile && !this.isSizeTablet ) this.createMainOnScrollTimeline()
			this.createMainTimeline()
			// GSDevTools.create( {
			// 	animation: "main-timeline"
			// } )
		} )
	}
}
</script>

<style lang="postcss">
.main-container {
	position: fixed;
	width: 100%;
	height: 100vh;

	&__visuals {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	&__content {
		position: relative;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
}
</style>